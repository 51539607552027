import Chart from 'chart.js/auto';

$(function() {
    todayStatistics();
    allTimeStatistics();
});

function todayStatistics() {
    axios.get(window.location.pathname + '/today')
        .then(function (data) {
            const today_statistics = document.getElementById('today_statistics');
            new Chart(today_statistics, {
                type: 'doughnut',
                data: {
                    labels: [
                        'Reallocated',
                        'On hold',
                        'Closed',
                        'Rolled',
                        'Discretionary',
                        'Discretionary Payment Made',
                    ],
                    datasets: [{
                        data: data.data,
                        backgroundColor: [
                            '#006B75',
                            '#9059c9',
                            '#f5b620',
                            '#f55620',
                            '#5A88D4',
                            '#343a40',
                            '#bd2130',
                        ],
                        hoverOffset: 4
                    }]
                }
            });
        });
}

function allTimeStatistics() {
    axios.get(window.location.pathname + '/all-time')
        .then(function (data) {
            const all_time_statistics = document.getElementById('all_time_statistics');
            new Chart(all_time_statistics, {
                type: 'doughnut',
                data: {
                    labels: [
                        'Reallocated',
                        'On hold',
                        'Closed',
                        'Rolled',
                        'Discretionary',
                        'Discretionary Payment Made',
                        'Unpaid'
                    ],
                    datasets: [{
                        data: data.data,
                        backgroundColor: [
                            '#006B75',
                            '#9059c9',
                            '#f5b620',
                            '#f55620',
                            '#5A88D4',
                            '#343a40',
                            '#bd2130',
                            '#ccc',
                        ],
                        hoverOffset: 4
                    }]
                }
            });
        });
}
