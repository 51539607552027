require('./bootstrap.js');
require('bootstrap-select');

const payment_urls = ['/crm-unpaid', '/survey-plans-and-works-available-under-licence', '/discretionary'];
if (payment_urls.includes(window.location.pathname)) {
    require('./payments.js');
}

const statistic_urls = ['/statistics'];
if (statistic_urls.includes(window.location.pathname)) {
    require('./statistics.js');
}
