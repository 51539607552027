$(function() {
    const urlParams = new URLSearchParams(window.location.search);
    const offset = parseInt($('#offset').text());
    const total = parseInt($('#total').text());
    const limit = parseInt($('#limit').val());

    selectAll();
    nextPage(offset, total, limit, urlParams);
    previousPage(offset, limit, urlParams);
    pagination(offset, limit, total, urlParams);
    changePageLimit(urlParams);
    processPayments();
    updateAccounts();
    sorting(urlParams);

    if (sessionStorage.message) {
        alertMessage('success', sessionStorage.message);
        delete sessionStorage.message;
    }

    if(sessionStorage.errorMessage) {
        alertMessage('warning', sessionStorage.errorMessage);
        delete sessionStorage.errorMessage;
    }

    $("input:checkbox[name=account_number]").on('change', function () {
        toggleApplyButton()
    });

    $("#payment_option").on('change', function () {
        toggleApplyButton()
    });

    $("#cancel_payment_button").on('click', function () {
        toggleApplyButton();
    });

    $('.my-select').selectpicker();

    $(document).on('keyup', 'input[type="search"]',delay( function () {
        getAccount();
    }, 500));

    $("#download_discretionary").on('click', function () {
        downloadDiscretionaryReport();
    });

    console.log("ready!");
});

function sorting(urlParams) {
    let sort_field = urlParams.get('sort_field');
    let sort_direction = urlParams.get('sort_direction');

    $('#' + sort_field + '_' + sort_direction).removeClass('text-light');

    $('.sort_up').on('click', function () {
        let res = this.id.split("_");
        sort_direction = res.pop();
        sort_field = res.join('_');
        urlParams.set('sort_direction', sort_direction);
        urlParams.set('sort_field', sort_field);
        window.location.search = urlParams;
    })
}

function nextPage(offset, total, limit, urlParams) {
    const next = $('#next');
    if (offset > total - limit) {
        next.hide();
    } else {
        next.show();
    }
    next.on('click', function () {
        let total_offset = limit + offset - 1;
        urlParams.set('offset', total_offset.toString());
        window.location.search = urlParams;
    });
}

function previousPage(offset, limit, urlParams) {
    const previous = $('#previous');
    if (offset <= 1) {
        previous.hide();
    } else {
        previous.show();
    }
    previous.on('click', function () {
        let total_offset = offset - limit - 1;
        urlParams.set('offset', total_offset.toString());
        window.location.search = urlParams;
    });
}

function pagination(offset, limit, total, urlParams) {
    let total_number_of_pages = total / limit;
    total_number_of_pages = Math.ceil(total_number_of_pages);
    let current_page = offset / limit + 1;
    current_page = Math.round(current_page);

    let page_range;
    if (current_page <= 2) {
        page_range = 1
    } else if (current_page >= total_number_of_pages - 3) {
        page_range = total_number_of_pages - 4
    } else {
        page_range = current_page - 2;
    }

    let results = "";
    for (let i = 0; i < 5; i++) {
        if (total < (page_range * limit - limit)) {
            break;
        }
        if (page_range >= 1) {
            urlParams.set('offset', (page_range * limit - limit).toString());
            if (page_range === current_page) {
                results += "<a class='btn btn-primary mr-1' href='" + location.pathname + "?" + urlParams.toString() + "'>" + page_range +"</a>"
            } else {
                results += "<a class='btn btn-outline-primary mr-1' href='" + location.pathname + "?" + urlParams.toString() + "'>" + page_range +"</a>"
            }
            urlParams.delete('offset')
        }
        page_range++;
    }

    $('#page_buttons').html(results);
}

function changePageLimit(urlParams) {
    $('#limit').on('change', function() {
        urlParams.set('limit', $(this).val());
        window.location.search = urlParams;
    });
}

function selectAll() {
    $("#checkAll").change(function () {
        $("input:checkbox").prop('checked', $(this).prop("checked"));
        toggleApplyButton()
    });
}

function processPayments() {
    const payment_option = $("#payment_option");

    $('#apply').on('click', async function () {
        $('#apply').prop('disabled', true);

        let ids = [];
        $("input:checkbox[name=account_number]:checked").each(function(){
            ids.push($(this).val());
        });

        if (payment_option.val() === 'reallocate_payment') {
            let on_hold_ids = await getPaymentLineOnHold();
            on_hold_ids = on_hold_ids.map(payment => payment.id);
            const showConfirmationModal = ids.some(id => on_hold_ids.includes(parseInt(id)));

            if (showConfirmationModal) {
                $('#onHoldConfirmationModal').modal('show');
                $('#proceed_payment_button').on('click', function () {
                    $('#onHoldConfirmationModal').modal('hide');
                    $('#reallocateModal').modal('show');
                });
            } else {
                $('#reallocateModal').modal('show');
            }
        }

        if (payment_option.val() === 'hold_payment_line') {
            holdPaymentLine(ids);
        }
        if (payment_option.val() === 'closed') {
            rolloverPaymentLine(ids);
        }

        if (payment_option.val() === 'discretionary_payment_made') {
            discretionaryPaymentMade(ids);
        }
    })
}

async function getPaymentLineOnHold() {
    let ids = [];
    await axios.get(window.location.pathname + '/getPaymentLineOnHold')
        .then(function (data) {
            ids = data.data['payment_ids'];
        })
        .catch(function (error) {
            alertMessage('danger', error.response.data.message);
            $('#apply').prop('disabled', false);
        });

    return ids;
}

function rolloverPaymentLine(ids) {
    axios.post(window.location.pathname + '/rolloverPaymentLine', {
        ids: ids,
    })
        .then(function (data) {
            sessionStorage.message = data.data.message;
            location.reload();
        })
        .catch(function (error) {
            alertMessage('danger', error.response.data.message);
            $('#apply').prop('disabled', false);
        });
}

function holdPaymentLine(ids) {
    axios.post(window.location.pathname + '/holdForPayment', {
        ids: ids,
    })
        .then(function (data) {
            sessionStorage.message = data.data.message;
            location.reload();
        })
        .catch(function (error) {
            alertMessage('danger', error.response.data.message);
            $('#apply').prop('disabled', false);
        });
}

function discretionaryPaymentMade(ids) {
    axios.post(window.location.pathname + '/paymentMade', {
        ids: ids,
    })
        .then(function (data) {
            sessionStorage.message = data.data.message;
            location.reload();
        })
        .catch(function (error) {
            alertMessage('danger', error.response.data.message);
            $('#apply').prop('disabled', false);
        });
}

function delay(fn, ms) {
    let timer = 0
    return function(...args) {
        clearTimeout(timer)
        timer = setTimeout(fn.bind(this, ...args), ms || 0)
    }
}

function getAccount() {
    $('#account_search_submit').prop('disabled', true);
    $('#account_search').find('option').remove().end();
    $('#apply').prop('disabled', false);
    $('#account_search_spinner').show();

    const value = $('input[type="search"]').val();
    axios.post(window.location.pathname + '/getAccounts', {
        value: value,
    })
        .then(function (response) {
            response.data.forEach(function (value) {
                let relationship_type = 'Self';
                let parent_name = value.Name;
                let member_status = value.MS_Member_Status__c;
                if (value.MS_Ultimate_Parent_Account__r !== null) {
                    relationship_type = 'Controlled By';
                    parent_name = value.MS_Ultimate_Parent_Account__r.Name;
                    member_status = value.MS_Ultimate_Parent_Account__r.MS_Member_Status__c;
                }

                let text =
                    "<div class='container-fluid'>" +
                    "<div class='row'>" +
                    "<div class='col-4'>" +
                    "<b>Account Name:</b><br>" +
                    "</div>" +
                    "<div class='col-8 text-wrap'>" +
                    "<span>" + value.Name + "</span>" +
                    "</div></div>" +
                    "<div class='row'>" +
                    "<div class='col-4'>" +
                    "<b>Account Profile:</b><br>" +
                    "</div>" +
                    "<div class='col-8 text-wrap'>" +
                    "<span>" + value.MS_Account_Profile__c + "</span>" +
                    "</div></div>" +
                    "<div class='row'>" +
                    "<div class='col-4'>" +
                    "<b>Relationship Type:</b><br>" +
                    "</div>" +
                    "<div class='col-8 text-wrap'>" +
                    "<span>" + relationship_type + "</span>" +
                    "</div></div>" +
                    "<div class='row'>" +
                    "<div class='col-4'>" +
                    "<b>Parent Account:</b><br>" +
                    "</div>" +
                    "<div class='col-8 text-wrap'>" +
                    "<span>" + parent_name + "</span>" +
                    "</div></div>" +
                    "<div class='row'>" +
                    "<div class='col-4'>" +
                    "<b>Account Number:</b><br>" +
                    "</div>" +
                    "<div class='col-8 text-wrap'>" +
                    "<span>" + value.MS_Account_Number__c + "</span>" +
                    "</div></div>" +
                    "<div class='row'>" +
                    "<div class='col-4'>" +
                    "<b>Member Status:</b><br>" +
                    "</div>" +
                    "<div class='col-8 text-wrap'>" +
                    "<span>" + member_status + "</span>" +
                    "</div></div>" +
                    "</div>" +
                    "</div>";
                $('#account_search').append('<option title="' +  value.Name + '" data-content="' + text + '" value="'+value.MS_Account_Number__c+'"></option>');

            });
            $("#account_search").selectpicker("refresh");
            $('#account_search_spinner').hide();
            $('#account_search_submit').prop('disabled', false);

        })
        .catch(function () {
            $('#account_search_spinner').hide();
        });
}

function updateAccounts() {
    $('#account_search_submit').on('click', function () {
        let ids = [];

        $("input:checkbox[name=account_number]:checked").each(function(){
            ids.push($(this).val());
        });

        axios.post(window.location.pathname + '/reallocatePayment', {
            ids: ids,
            new_account_number: $('#account_search').val(),
        })
            .then(function (data) {
                sessionStorage.message = data.data.message;
                location.reload();
            })
            .catch(function (error) {
                if (error.response.status === 400) {
                    sessionStorage.errorMessage = error.response.data.message;
                    location.reload();
                } else {
                    alertMessage('danger', error.response.data.message);
                    $('#apply').prop('disabled', false);
                }
            });
    })
}

function alertMessage(type, message) {
    if (message == null && type === 'danger') {
        message = 'Oops something went wrong, please try again.';
    }
    let html = "<div class='alert alert-" + type + " alert-dismissible fade show' role='alert'>" +
        message +
        "  <button type='button' class='close' data-dismiss='alert' aria-label='Close'>" +
        "    <span aria-hidden='true'>&times;</span>" +
        "  </button>" +
        "</div>";
    $('#alert').html(html);
}

function toggleApplyButton() {
    if ($("input:checkbox[name=account_number]:checked").length > 0 && $('#payment_option').val() !== '') {
        $('#apply').prop('disabled', false);
    } else {
        $('#apply').prop('disabled', true);
    }
}

async function transferToXLS(collection = []) {
    const newCollection = JSON.parse(JSON.stringify(collection));

    let wb = XLSX.utils.book_new();
    let ws_name = "Discretionary Payment Report";
    let ws = XLSX.utils.json_to_sheet(newCollection);
    XLSX.utils.book_append_sheet(wb, ws, ws_name);

    XLSX.writeFile(wb, 'DiscretionaryPaymentReport.xlsx');
}

async function downloadDiscretionaryReport() {
    let dataRow = [];
    await axios.get(window.location.pathname + '/getDiscretionaryReport')
        .then(function (data) {
            dataRow = data['data'];
        })
        .catch(function (error) {
            alertMessage('danger', error.response.data.message);
        });
    await transferToXLS(dataRow);
}
